<template>
  <card-form :loading="loading">

    <div class="row mt-4">

      <f-input 
        name="descricao" 
        :disabled="disabled"
        v-model="model.descricao" 
        v-validate="{required: true}" 
        data-vv-as="Descrição"
        label="Descrição"
        :modelErrors="errors"
        :cols="6"
      />

      <div v-if="$route.params.id > 0" class="col-md-6">
        <fieldset class="form-group mb-3 form-group-input">
          <legend class="bv-no-focus-ring col-form-label pt-0">
            Representação
          </legend>
          <div class="representacao">{{ model.representacao }}</div>
        </fieldset>
      </div>
      <f-selectsearch v-else
        label="Representação"
        name="representacaoId"
        ref="representacaoId"
        v-model="model.representacaoId"
        v-validate="{required: true}" 
        data-vv-as="Representação"
        labelField="nome"
        :modelErrors="errors"
        :search-function="getAllRepresentacao"
        :remove-options-on-select="false"
        :hide-selected="false"
        :cols="6"

        @select="selecionarRepresentacao"
      />

      <f-switch
        name="ativo"
        :disabled="disabled"
        v-model="model.ativo"
        :cols="12"
        :label="model.ativo ? 'Ativo' : 'Inativo'"
      />

      <div class="col-md-12">
        <fieldset class="form-group mb-3 form-group-input">
          <legend class="bv-no-focus-ring col-form-label pt-0">
            Módulos
          </legend>

          <tree v-if="model.representacaoId > 0" class="tree--small" :data="allModulos" :options="treeOptions" ref="tree">
            <span class="tree-text" slot-scope="{ node }">
              <template>
              <i :class=" ` ${ node.data.icon }`"></i>
              {{ node.text}}
              </template>
            </span>
          </tree>
          <div v-else class="alert alert-warning" role="alert">Selecione a representação</div>
        </fieldset>
      </div>

    </div>

    <template #footer>
      <button v-if="!disabled" class="btn btn-success px-5" @click="submeter" type="button">{{ model.id == 0 ? 'Criar' : 'Alterar' }}</button>
      <button class="btn btn-info px-5" @click="() => {$router.push({name: 'sistema.perfil.lista'})}" type="button">Voltar</button>
      <button v-if="model.id > 0" class="btn btn-outline-info px-4" @click="() => {$router.push({name: 'sistema.perfil.usuarios.lista', params: { id: model.id, backTo: $router.currentRoute.path } })}" type="button" title="Usuários">
        <i class="uil uil-users-alt"></i>
      </button>
    </template>

  </card-form>

</template>

<script>
import CardForm from "@/components/Ui/CardForm";
import { getPerfil, criarPerfil, atualizarPerfil, getModuloTree } from "@/services/perfil.service";
import { getAllRepresentacao } from "@/services/usuario.service";
import { trataErro, trataUpdateSucesso } from "../../../../utils/tratar.retornos";
import { messageError } from "../../../../utils/messages";
import liquorTree from "liquor-tree";
import FSelectsearch from '@/components/Form/SelectSearch';

export default {
  components: {
    CardForm,
    FSelectsearch,
    tree: liquorTree,
  },
  data() {
    return {
      getAllRepresentacao,

      disabled: true,
      loading: false,
      model: {
        id: 0,
        descricao: '',
        representacao: '',
        representacaoId: null,
        ativo: true,
        modulos: [],
      },
      
      allModulos: [],
      treeOptions: {
        checkbox: true
      },

      representacoes: [],
    };
  },
  mounted() {
    this.disabled = !this.$route.meta.editavel;

    if (this.$route.params.id && this.$route.params.id > 0) {
      this.loading = true;

      getPerfil(this.$route.params.id)
        .then(({ data }) => {
          for (let key in data.resultado) {
            this.model[key] = data.resultado[key];
          }

          // Label da representacao.
          getAllRepresentacao()
            .then(({ data }) => {
              let opts = data.resultado;
              for (let i = opts.length - 1; i >= 0; i--) {
                if (opts[i].id == this.model.representacaoId) {
                  this.model.representacao = opts[i].nome;
                  break;
                }
              }
            })
            .finally(() => {
              this.carregarModulo();
            });
        })
        .catch((e) => 
          trataErro(e, this)
        )
        .finally(() => {
          this.loading = false;
        });
    } else {
      getAllRepresentacao().then(({ data }) => {
        this.$refs.representacaoId.setOptions(data.resultado);
      })
    }
  },
  methods: {
    setDisabledNoPai(obj) {
        obj.children.forEach(m1 => {
            m1.state.disabled = true;
            if (m1.children.length > 0) {
              this.setDisabledNoPai(m1);
            }
        });
    },
    async reloadTree() {
      if (this.disabled) {
          await this.allModulos.forEach(m => {
            m.state.disabled = true;
            if (m.children.length > 0) {
                this.setDisabledNoPai(m);
            }
          });
      }
      this.$refs.tree.tree.setModel(this.allModulos);
    },

    carregarModulo() {
      if (!this.model.representacaoId) {
        this.allModulos = [];
      } else {
        this.loading = true;

        getModuloTree({ 
            representacaoId: this.model.representacaoId, 
            idsSelecionados: this.model.modulos
          })
          .then(({ data }) => {
            this.allModulos = data.resultado; 
            this.reloadTree();
          })
          .finally(() => {
            this.loading = false;
          })
      }
    },

    loadNodeIds() {
      this.model.modulos = [];
      var selectedModulos = this.$refs.tree.tree.checkedNodes;
      if (selectedModulos != null) {
          for (var idx  = 0; idx < selectedModulos.length; idx++) {
            let m = selectedModulos[idx];
            this.model.modulos.push(m.id);
          }
      }

      selectedModulos = this.$refs.tree.findAll({state: { indeterminate: true } });
      if (selectedModulos != null) {
          for (var idx1  = 0; idx1 < selectedModulos.length; idx1++) {
            let m = selectedModulos[idx1];
            this.model.modulos.push(m.id);
          }
      }
    },

    async submeter(event) {
      event.preventDefault()
      var isValid = await this.$validator.validateAll();

      if (isValid) {
        this.loadNodeIds();
        if (this.model.modulos.length == 0) {
          messageError(this, "Selecione ao menos uma funcionalidade para o perfil.");
          isValid = false;
        }
      }
      if (isValid) {
        if (this.model.id > 0) {
          atualizarPerfil(this.model).then(() => {
            trataUpdateSucesso(this, 'sistema.perfil.lista')
          })
          .catch((e) => 
            trataErro(e, this)
          )
        } else {
          criarPerfil(this.model).then(() => {
            trataUpdateSucesso(this, 'sistema.perfil.lista')
          })
          .catch((e) => 
            trataErro(e, this)
          )
        }
        
      }
    },

    selecionarRepresentacao(novaRepresentacao) {
      if (novaRepresentacao) {
        this.model.representacaoId = novaRepresentacao.id;
      } else {
        this.model.representacaoId = null;
      }

      this.carregarModulo();
    },

  },
};
</script>

<style lang="scss" scoped>
.representacao {
  font-size: 1.5rem;
  line-height: 1.75rem;
}
</style>